// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "select2-bootstrap";
// Custom
@import "custom";

// Datatable
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.css';

// Select2
@import '~select2/dist/css/select2.min.css';


// Bootstrap DatePicker
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

// SweetAlert2
@import '~sweetalert2/dist/sweetalert2.min.css';

// Filepond
@import '~filepond/dist/filepond.min.css';

// Filepond Preview
@import '~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';


// AdminLTE
@import '~admin-lte/dist/css/alt/adminlte.core.min.css';
//@import '~admin-lte/dist/css/alt/adminlte.components.min.css';
@import '~admin-lte/dist/css/alt/adminlte.extra-components.min.css';
//@import '~admin-lte/dist/css/adminlte.min.css';

//FANOS: IF THERE IS A PROBLEM WITH BOOTSTRAP COLORS, COMMENT OUT THE extra-components.min.css
