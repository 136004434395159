@import "~@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4";

button.select2-selection__choice__remove {
    border: none;
    color: #7f7f7f;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove {
    border: none;
    //color: #7f7f7f;
    color: #000000;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove span {
    padding: 4px;
}

li.select2-selection__choice {
    background-color: #e6e6e6;
}

.input-group span.select2-selection.select2-selection
    /*, .input-group span.select2-selection.select2-selection > * */
{
    height: 100%!important;
}

.input-group span.select2-selection.select2-selection > * {
    /*padding: 0.375rem 0.75rem;*/
    font-size: 0.9rem;
    /*font-weight: 400;*/
    /*line-height: 1.6;*/
    /*border-radius: 0.25rem;*/
}



.input-group.input-group-lg span.select2-selection.select2-selection > * {
    /*padding: 0.5rem 1rem;*/
    font-size: 1.125rem;
    /*line-height: 1.5;*/
    /*border-radius: 0.3rem;*/
}


.input-group.input-group-sm span.select2-selection.select2-selection > * {
    /*padding: 0.25rem 0.5rem;*/
    font-size: 0.7875rem;
    /*line-height: 1.5;*/
    /*border-radius: 0.2rem;*/
}




